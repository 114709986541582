import { $EventBus } from '@/main';
import CbodyDatePicker from '@/components/inspections/inputs/CbodyDatePicker.vue';

export default {
	name: 'EvaluationDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		itemEdit: {
			type: Object,
			required: false,
		},
	},
	data: () => ({
		valid: null,
		form: {
			description: null,
			quantity: 1,
			status: null,
			expiration_date: null,
			observations: null,
		},
		default: {
			description: null,
			quantity: null,
			status: null,
			expiration_date: null,
			observations: null,
		},
		answers: [
			{ label: 'Bueno', value: 'BUENO', color: 'success' },
			{ label: 'Regular', value: 'REGULAR', color: 'yellow' },
			{ label: 'Malo', value: 'MALO', color: 'red' },			
			{ label: 'No aplica', value: 'NO APLICA', color: 'grey' },
		],
		rules: [(v) => !!v || 'Campo requerido'],
		quantityRules: [
			(v) => !!v || 'Campo requerido',
			(v) => /^\d*$/.test(v) || 'Campo numérico',
			(v) => v > 0 || 'Campo numérico',
		],
		isValueNegative: false,
	}),
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal && this.itemEdit) {
				this.form = { ...this.itemEdit };
				this.listenChangeStatus();
			}
		},
	},
	methods: {
		saveEvaluation() {
			const isCompletedForm = this.$refs.form.validate();
			if (isCompletedForm) {
				this.$emit('addItem', JSON.parse(JSON.stringify(this.form)));
				this.closeEvaluation();
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Evaluación');
			}
		},

		closeEvaluation() {
			this.$nextTick(() => {
				if (this.$refs?.form) {
					this.$refs.form.reset();
				}
				this.form = { ...this.default };
				this.dialog = false;
			});
		},

		listenChangeStatus() {
			if (['NO TIENE', 'NO APLICA'].includes(this.form.status)) {
				this.isValueNegative = true;
				this.form.quantity = null;
				this.form.expiration_date = null;
			} else {
				this.isValueNegative = false;
				this.form.quantity = 1;
			}
		},
	},
	components: { CbodyDatePicker },
};
