import { mapActions } from 'vuex';
import moment from 'moment';
import CbodyDatePicker from '@/components/inspections/inputs/CbodyDatePicker.vue';
import { $EventBus } from '@/main';

export default {
	name: 'EvaluationTable',
	props: {
		value: {
			type: Array,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		tool: null,
		headers: [
			{
				text: 'IT',
				value: 'index',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Descripción',
				value: 'description',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Cant.',
				value: 'quantity',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Estado',
				value: 'status',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Fecha de vencimiento',
				value: 'expiration_date',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Observación',
				value: 'observations',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],
	}),
	computed: {
		items: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		// value(newVal) {
		// 	if (newVal) {
		// 		this.tool = this.toolEvaluation;
		// 		if (Array.isArray(this.usersActive) && this.usersActive.length == 0) {
		// 			this.getUsersActive();
		// 		}
		// 	}
		// },
	},
	created() {
		this.addHeaderInTable();
	},
	methods: {
		...mapActions('security', ['getUsersActive']),

		isFormCompleted() {
			const hasIncompleteEvaluation = this.tool.criterias.some((item) => {
				if (item.value == undefined) {
					return true;
				} else if (item.value == 'NO' && !item.actions) {
					return true;
				} else if (item.value == 'NO' && !item.responsible_id) {
					return true;
				} else if (item.value == 'NO' && !item.date) {
					return true;
				} else {
					return false;
				}
			});
			return !hasIncompleteEvaluation;
		},

		hasObservation() {
			const result = this.tool.criterias.some((item) => {
				return item.value && item.value == 'NO';
			});
			return result;
		},

		saveEvaluation() {
			if (this.isFormCompleted()) {
				this.tool.hasObservation = this.hasObservation();
				this.tool.isEvaluated = true;
				this.$emit('saveToolEvaluation', this.tool);
				this.toolDialog = false;
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar Evaluación');
			}
		},

		removeEvaluation(id) {
			const index = this.items.findIndex((element) => element.currentId == id);
			if (index >= 0) this.items.splice(index, 1);
		},

		editItem(item) {
			this.$emit('editItem', JSON.parse(JSON.stringify(item)));
		},

		formatDate(date) {
			if (date) {
				try {
					return date && moment(date).format('DD-MM-YYYY');
				} catch (error) {
					return date;
				}
			} else {
				return date;
			}
		},

		addHeaderInTable() {
			if (!this.disabled) {
				this.headers.push({
					text: 'Acciones',
					value: 'actions',
					sortable: false,
					align: 'end',
					class: 'primary--text',
				});
			}
		},
	},
	components: {
		CbodyDatePicker,
	},
};
